import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountAdminSettingsComponent } from './pages/account-admin-settings/account-admin-settings.component';
import { UsersAdminSettingsComponent } from './pages/users-admin-settings/users-admin-settings.component';


const routes: Routes = [
  {
    path: '',
    component: AccountAdminSettingsComponent,
  },
  {
    path: 'users',
    component: UsersAdminSettingsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsAdminRoutingModule { }
