import { HttpService } from '../../../@core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiSettingsService {

  constructor(
    private api: HttpService
  ) { }

  getApiKeys(): Observable<any> {

    return this.api.get(`apikeys`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  createApiKeys(name: string): Observable<any> {
    const data = {"name": name};
    return this.api.post(`apikeys`, data).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  deleteApiKeys(apikey_id: string): Observable<any> {

    return this.api.delete(`apikeys/${apikey_id}`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }
}