import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from "rxjs";
import { Router } from '@angular/router';
import { AccountsAdmin } from '../../models/accounts_admin';
import { AccountAdminSettingsService } from '../../services/account-admin-settings.service';
import { DialogAccountAdminComponent } from '../dialog-account-admin/dialog-account-admin.component';

@Component({
  selector: 'hrt-account-admin-settings',
  templateUrl: './account-admin-settings.component.html',
  styleUrl: './account-admin-settings.component.scss'
})
export class AccountAdminSettingsComponent {
  displayedColumns: string[] = ['name', 'id', 'users'];
  pageSize = [10, 25, 50, 100];
  dataSource: MatTableDataSource<AccountsAdmin>;
  element_data: AccountsAdmin[];
  private searchRequestSub: Subscription[] = [];

  constructor(public dialog: MatDialog,public AccountAdminSettingsService: AccountAdminSettingsService,
              private router: Router) {
    this.getAccounts();
  }

  refreshTable() {
    this.dataSource = new MatTableDataSource<any>(this.element_data);
  }

  getAccounts(search: string = null) {
    this.element_data = [];
    const searchSub = this.AccountAdminSettingsService
      .getAccounts(search)
      .subscribe({
        next: (data: any) => {
          data = Object.keys(data).map(function (key) { return data[key]; });
          for (const elem of data) {
            this.element_data.push(new AccountsAdmin(elem.id, elem.name, elem.createdAt, elem.updatedAt));
          }
          this.refreshTable();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });

    this.searchRequestSub.push(searchSub);

  }

  openDialog(element: AccountsAdmin = null, type: string = null): void {

    const dialogRef = this.dialog.open(DialogAccountAdminComponent, { data: { element, type } });

    dialogRef.afterClosed().subscribe(() => {
      this.getAccounts();
    });
  }

  goBack() {
    const url = `/modules/`;
    this.router.navigateByUrl(url);
  }

  isChecked(status: string) {
    if (status == "active")
      return true;
    return false;
  }

  moveToUsers(id: string) {
    const url = `/modules/AccountAdminSettings/users?account_id=${id}`;
    this.router.navigateByUrl(url);
  }

  onSearch(search: string) {
    this.cancelPendingRequests();
    this.getAccounts(search);
  }

  cancelPendingRequests() {
    this.searchRequestSub.forEach(sub => sub.unsubscribe());
  }
}
