import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountAdminSettingsService } from '../../services/account-admin-settings.service';
import { AccountsAdmin } from '../../models/accounts_admin';

@Component({
  selector: 'hrt-dialog-account-admin',
  templateUrl: './dialog-account-admin.component.html',
  styleUrl: './dialog-account-admin.component.scss'
})
export class DialogAccountAdminComponent {

  public title: "create";
  public account: AccountsAdmin = null;
  public time = {hours: 0, minutes: 0};

  accountForm = this.formBuilder.group({
    name: ['', Validators.required],
    cameraLicences: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
  });

  constructor(private dialogRef: MatDialogRef<DialogAccountAdminComponent>,
    private formBuilder: FormBuilder,
    private AccountAdminSettingsService: AccountAdminSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  createNewAccount() {
    this.AccountAdminSettingsService
      .createAccount(this.accountForm.value.name, this.accountForm.value.cameraLicences)
      .subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  get cameraLicences() {
    return this.accountForm.get('cameraLicences');
  }

  apply() {
    if (this.accountForm.status == 'VALID') {
      this.createNewAccount();
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
