<div class="page">
    <div class="container" *ngIf="completed == false">
        <div class="title-container icon-primary">
            {{"change password" | translate}}
        </div>
        <form class="container-form" [formGroup]="pwdForm" (ngSubmit)="apply()">
            <mat-form-field appearance="outline">
                <mat-label>{{"oldPwd" | translate}}</mat-label>
                <input matInput formControlName="oldPwd" [type]="hideOld ? 'password' : 'text'">
                <button type="button" class="icon-gray" mat-icon-button matSuffix (click)="hideOld = !hideOld"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideOld">
                    <mat-icon>{{ hideOld ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{"newPwd" | translate}}</mat-label>
                <input matInput formControlName="newPwd" [type]="hideNew ? 'password' : 'text'">
                <button type="button" class="icon-gray" mat-icon-button matSuffix (click)="hideNew = !hideNew"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNew">
                    <mat-icon>{{ hideNew ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{"confirmation" | translate}}</mat-label>
                <input matInput formControlName="confirmationPwd" [type]="hideConf ? 'password' : 'text'">
                <button type="button" class="icon-gray" mat-icon-button matSuffix (click)="hideConf = !hideConf"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConf">
                    <mat-icon>{{ hideConf ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
            </mat-form-field>
            <div class="list">
                - {{"passwordSpecs.char" | translate}}
                <mat-icon [ngClass]="{'green': isEightChar()}" class="grey">check</mat-icon>
            </div>
            <div class="list">
                - {{"passwordSpecs.digit" | translate}}
                <mat-icon [ngClass]="{'green': isDigit()}" class="grey">check</mat-icon>
            </div>
            <div class="list">
                - {{"passwordSpecs.special" | translate}}
                <mat-icon [ngClass]="{'green': isSpecial()}" n class="grey">check</mat-icon>
            </div>
            <div class="message alert">{{errorMsg | translate}}</div>
            <div class="button-container">
                <button mat-raised-button color="primary" class="button">{{"yes" | translate}}</button>
                <button mat-raised-button color="primary" class="button" type="button"
                    (click)="closeDialog()">{{"Cancel" |
                    translate}}</button>
            </div>
        </form>
    </div>

</div>

<div *ngIf="completed" class="question-container">
    <div class="question">
        {{"You successfully changed your password !" | translate}}
    </div>
</div>