import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { UserSettingsService } from '../../services/user-settings.service';
import { userDialogControl } from '../../models/userDialogControl';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'hrt-dialog-source',
  templateUrl: './dialog-source.component.html',
  styleUrl: './dialog-source.component.scss'
})
export class DialogSourceComponent {

  apicontrol: userDialogControl = userDialogControl.Create;
  title: string = "register";
  establishmentId: string = '';

  constructor(private dialogRef: MatDialogRef<DialogSourceComponent>,
    private formBuilder: FormBuilder,
    private userSettingsService: UserSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
  ) { }


  ngOnInit() {
    if (this.data.establishmentId) {
      this.establishmentId = this.data.establishmentId;
    }
  }

  userForm = this.formBuilder.group({
    description: ['', Validators.required],
    source: ['', Validators.required],
    type: ['', Validators.required],
    label: ['', Validators.required],
    cx: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    cy: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    r: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    orientation: ['', Validators.required],
  });

  get cx () {
    return this.userForm.get('cx');
  }

  get cy () {
    return this.userForm.get('cy');
  }

  get r () {
    return this.userForm.get('r');
  }

  createNewSource() {
    this.userSettingsService
      .createNewSource(this.createBodySource(), this.establishmentId)
      .subscribe({
        next: () => {
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  createBodySource() {
    return (
      {
        "hertaSource": this.userForm.value.source,
        "description": this.userForm.value.description,
        "type": this.userForm.value.type,
        "label": this.userForm.value.label,
        "location": {
          "cx": this.userForm.value.cx,
          "cy": this.userForm.value.cy,
          "r": this.userForm.value.r,
          "orientation": this.userForm.value.orientation
        }
      }
    );
  }

  apply() {
    if (this.userForm.status == 'VALID') {
      this.createNewSource();
    }
    this.dialogRef.close(this.userForm);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isAllValid() {
    return this.userForm.valid;
  }

}
