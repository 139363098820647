<div class="container-dialog" *ngIf="!isCreationDone">
    <div class="title">
        <h2 class="icon-secondary">{{title | translate}} {{"establishment" | translate}}</h2>
    </div>
    <form class="container-form" [formGroup]="userForm" (ngSubmit)="apply()">
        <mat-form-field appearance="outline">
            <mat-label>{{"userSettingsPage.name" | translate}}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{"capacity" | translate}}</mat-label>
            <input matInput formControlName="capacity">
        </mat-form-field>
        <div *ngIf="capacity.invalid && (capacity.dirty || capacity.touched)" class="errorMsg">
            <div>
                {{"invalid number" | translate}}
            </div>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>{{"gmt" | translate}}</mat-label>
            <input matInput formControlName="gmt">
        </mat-form-field>
        <div *ngIf="gmt.invalid && (gmt.dirty || gmt.touched)" class="errorMsg">
            <div>
                {{"invalid number" | translate}}
            </div>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>{{"heatMapThresholds min" | translate}}</mat-label>
            <input matInput formControlName="min">
        </mat-form-field>
        <div *ngIf="min.invalid && (min.dirty || min.touched)" class="errorMsg">
            <div>
                {{"invalid number" | translate}}
            </div>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>{{"heatMapThresholds max" | translate}}</mat-label>
            <input matInput formControlName="max">
        </mat-form-field>
        <div *ngIf="max.invalid && (max.dirty || max.touched)" class="errorMsg">
            <div>
                {{"invalid number" | translate}}
            </div>
        </div>
        <div class="button-row">
            <button [disabled]="!isAllValid()" mat-raised-button color="primary" type="submit">{{"Apply" |
                translate}}</button>
            <button mat-raised-button class="icon-secondary" (click)="closeDialog()" type="button">{{"Cancel" |
                translate}}</button>
        </div>
    </form>
</div>

<div class="container-success" *ngIf="isCreationDone">
    <div class="title">
        <h2 class="icon-secondary">{{"Establishment successfully created."| translate}}</h2>
    </div>
    <div class="content-text percent38">
        <div class="ptop">The configuration file will be automatically downloaded. If the download doesn't start after few seconds,
            click here to download it.</div>
    </div>
    <div class="button-middle percent38">
        <button mat-flat-button color="primary" (click)="downloadConfig()">
            <h2 class="inside-button">
                {{"Download" | translate}}
            </h2>
        </button>
    </div>
    <div class="right-side-button">
        <button mat-raised-button color="primary" (click)="closeDialog()">{{"Close" | translate}}</button>
    </div>
</div>