import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountSettingsService } from '../../services/account-settings.service';
import { subPlan } from '../../../../@shared/models/subPlans';
import { AdminSettingsDialog } from '../../models/adminSettingsEnum';
import { Accounts } from '../../models/accounts';

@Component({
  selector: 'hrt-dialog-account',
  templateUrl: './dialog-account.component.html',
  styleUrl: './dialog-account.component.scss'
})
export class DialogAccountComponent {

  public title: AdminSettingsDialog = AdminSettingsDialog.create;
  public account: Accounts = null;
  public time = {hours: 0, minutes: 0};

  accountForm = this.formBuilder.group({
    name: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    usagePlanId: [''],
    planType: [subPlan.Basic, Validators.required],
    calendar: [{ value: null, disabled: true }, Validators.required],
  });

  constructor(private dialogRef: MatDialogRef<DialogAccountComponent>,
    private formBuilder: FormBuilder,
    private AccountSettingsService: AccountSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  createNewAccount() {
    this.AccountSettingsService
      .createAccount(this.accountForm.value.name, this.accountForm.value.firstName, this.accountForm.value.lastName, this.accountForm.value.email,
        this.accountForm.value.usagePlanId)
      .subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  get email() {
    return this.accountForm.get('email');
  }

  apply() {
    if (this.accountForm.status == 'VALID') {
      this.createNewAccount();
    }
  }

  isAllValid() {
    return this.accountForm.valid;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
