import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../@shared/shared.module';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ChangePasswordRoutingModule } from './ChangePassword-routing.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [SharedModule, CommonModule, ChangePasswordRoutingModule,
            MatCardModule, MatIconModule, MatFormFieldModule, MatInputModule,
          MatButtonModule],
})
export class ChangePasswordModule { }
