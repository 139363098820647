import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ApiSettingsComponent } from './pages/api-settings/api-settings.component';
import { ApiSettingsRoutingModule } from './api-settings-routing.module';
import { DialogApiComponent } from './pages/dialog-api/dialog-api.component';

@NgModule({
  declarations: [ApiSettingsComponent, DialogApiComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, ApiSettingsRoutingModule, TranslateModule, SharedModule,
    MatInputModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatFormFieldModule, MatButtonModule,
    MatSlideToggleModule, MatMenuModule
  ],
  exports: [ApiSettingsComponent, DialogApiComponent],
})
export class ApiSettingsModule { }
