<div class="container-dialog" *ngIf="settings == 'Create'">
    <div class="title">
        <h2 class="icon-secondary">{{title | translate}} API {{"key" | translate}}</h2>
    </div>
    <form class="container-form" [formGroup]="userForm" (ngSubmit)="apply()">
        <mat-form-field appearance="outline">
            <mat-label>{{"userSettingsPage.name" | translate}}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <div class="button-row">
            <button mat-raised-button color="primary" class="button" type="submit">{{"Apply" | translate}}</button>
            <button mat-raised-button class="icon-secondary" (click)="closeDialog()" type="button">{{"Cancel" | translate}}</button>
        </div>
    </form>
</div>
<div class="container-secret" *ngIf="settings == 'Secret'">
    <div class="title">
        <h2 class="icon-secondary">{{'apiDialogSettings.keyCreated' | translate}}</h2>
    </div>
    <div>
        {{'apiDialogSettings.secretKey' | translate}}
    </div>
    <div class="spacer">
        {{secret}}
    </div>
    <button mat-raised-button class="icon-secondary" (click)="closeDialog()">Close</button>
</div>
<div class="container-secret" *ngIf="settings == 'Delete'">
    <div class="title">
        <h2 class="icon-secondary">{{'apiDialogSettings.deleteKey' | translate:apiKeyName}}</h2>
    </div>
    <div class="spacer">
        {{secret}}
    </div>
    <div class="button-row">
        <button mat-raised-button color="primary" class="button"(click)='deleteApiKey()'>{{'delete' | translate}}</button>
        <button mat-raised-button class="icon-secondary" (click)="closeDialog()" >{{"Cancel" | translate}}</button>
    </div>
</div>