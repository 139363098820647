export class EstablishmentSettings {
    public name: string;
    public id: string;
    public accountId: string;
    public sources: any[];
    public capacity: number;
    public gmt: number;
    public heatMapThresholds = {
      min: 0,
      max: 0
    };
    constructor(
        _name: string,
        _id : string,
        _accountId: string,
        _sources: any[],
        _capacity: number,
        _gmt: number,
        _heatmapThresholds: any
    ) { 
        this.name = _name;
        this.id = _id;
        this.accountId = _accountId;
        this.sources = _sources;
        this.capacity = _capacity;
        this.gmt = _gmt;
        this.heatMapThresholds.min = _heatmapThresholds.min;
        this.heatMapThresholds.max = _heatmapThresholds.max;
    }
}