import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationService } from '../../services/navigation.service';


@Component({
  selector: 'hrt-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {

  hideOld = true;
  hideNew = true;
  hideConf = true;
  errorMsg: string = null;
  completed: boolean = false;
  pwdForm = this.formBuilder.group({
    oldPwd: ['', Validators.required],
    newPwd: ['', Validators.required],
    confirmationPwd: ['', Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private navService: NavigationService) {
  }

  apply() {
    if (this.pwdForm.valid && this.pwdForm.value.newPwd == this.pwdForm.value.confirmationPwd) {
      this.navService.changePassword(this.pwdForm.value.oldPwd, this.pwdForm.value.newPwd)
        .subscribe({
          next: () => {
            this.completed = true;
          },
          error: (error: any) => {
            if (error.error?.detail) {
              this.errorMsg = error.error.detail;
            }
            console.error("something went wrong.");
          },
        });
    }
  }

  isEightChar() {
    const pwd = this.pwdForm.value.newPwd;
    const upperLower = /(?=.*[a-z])(?=.*[A-Z])/;
    if (pwd && pwd.length >= 8)
      return upperLower.test(pwd);
    return false;
  }

  isDigit() {
    const pwd = this.pwdForm.value.newPwd;
    if (!pwd)
      return false;
    const hasNumber = /\d/;
    return hasNumber.test(pwd);
  }

  isSpecial() {
    const pwd = this.pwdForm.value.newPwd;
    if (!pwd)
      return false;
    const isSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return isSpecial.test(pwd);
  }
}