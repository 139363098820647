<div class="container-dialog">
    <div class="title">
        <h2 class="icon-secondary">{{title | translate}}</h2>
    </div>
    <form class="container-form" [formGroup]="accountForm" (ngSubmit)="apply()">
        <mat-form-field appearance="outline">
            <mat-label>{{"userSettingsPage.name" | translate}}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{"cameraLicences" | translate}}</mat-label>
            <input matInput formControlName="cameraLicences">
        </mat-form-field>
        <div *ngIf="cameraLicences.invalid && (cameraLicences.dirty || cameraLicences.touched)" class="errorMsg">
            <div>
                {{"invalid number" | translate}}
            </div>
        </div>
        <div class="button-row">
            <button mat-raised-button color="primary" type="submit">{{"Apply" | translate}}</button>
            <button mat-raised-button class="icon-secondary" (click)="closeDialog()" type="button">{{"Cancel" |
                translate}}</button>
        </div>
    </form>
</div>