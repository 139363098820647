import { HttpService } from '../../../@core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {

  constructor(
    private api: HttpService
  ) { }

  getEstablishments(accountId: string): Observable<any> {

    return this.api.get(`establishments?page=1&limit=100&account_id=${accountId}`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  deleteEstablishment(accountId: string, establishmentId: string) {
    return this.api.delete(`accounts/${accountId}/establishments/${establishmentId}`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  regenerateApiKey(accountId: string, establishmentId: string) {
    return this.api.patch(`accounts/${accountId}/establishments/${establishmentId}/apikey`, {}).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  downloadConfigFile(establishment_id: string, apikey: string) {
    return this.api.get(`clientconfiguration?establishment_id=${establishment_id}&apikey=${apikey}'`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }
}