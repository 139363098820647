<div class="container">
  <div class="top-container">
    <button mat-fab color="primary" class="button" (click)="goBack()">
      <mat-label>
        <mat-icon>arrow_back</mat-icon>
      </mat-label>
    </button>
    <search-input (textChange)="onSearch($event)" />
    <button mat-fab color="primary" class="button" (click)="openDialog()">
      <mat-label>
        <mat-icon>add</mat-icon>
      </mat-label>
    </button>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{'Id' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'companyName' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="establishments">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button color="primary" (click)="moveToEstablishmentPage(element.id)">
            <div class="white">{{"establishments" | translate}}</div>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button color="primary" (click)="delete(element.id)">
            <div class="white">{{"delete" | translate}}</div>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="dataSource ? dataSource.data.length : 0" [pageSize]="10" [pageSizeOptions]="pageSize"
      aria-label="Select page of cameras" class="mat-elevation-z8">
    </mat-paginator>
  </div>
</div>