import { HttpService } from '../../../@core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccountAdminSettingsService {

  constructor(
    private api: HttpService
  ) { }

  getAccounts(search: string): Observable<any> {

    let query = `accounts_admin?page=1&limit=100&include_disabled=true`;

    if (search)
      query = `accounts_admin?page=1&limit=100&include_disabled=true&search=${search}`;

    return this.api.get(query).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  getUsers(account_id: string, search?: string): Observable<any> {

    let query = `users_admin?page=1&limit=100&include_disabled=true&account_id=${account_id}`;

    if (search)
      query = `users_admin?page=1&limit=100&include_disabled=true&search=${search}&account_id=${account_id}`;

    return this.api.get(query).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  createAccount(name: string, cameraLicences: string): Observable<any> {
    const data = { "name": name, "camera_licenses": cameraLicences};
    return this.api.post(`accounts_admin`, data).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  createUserAdmin(body: any): Observable<any> {
    return this.api.post(`users_admin`, body).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  deleteAccount(id: string) {
    const data = {"id": id};
    return this.api.delete(`accounts/${id}`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }
}