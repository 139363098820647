import { HttpService } from '../../../@core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {

  constructor(
    private api: HttpService
  ) { }

  getEstablishment(): Observable<any> {

    return this.api.get('users').pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  createNewEstablishment(data, accountId: string): Observable<any> {
    return this.api.post(`accounts/${accountId}/establishments`, data).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  createNewSource(data, establishmentId: string): Observable<any> {
    return this.api.post(`establishments/${establishmentId}/sources`, data).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  modifyUser(id: string, data: any) {
    return this.api.patch(`users/${id}`, data).pipe(
      map((result) => {
        return { ...result };
      })
    )
  }

  modifyUserPwd(id: string, data: any) {
    return this.api.patch(`users/${id}/password`, data).pipe(
      map((result) => {
        return { ...result };
      })
    )
  }

  downloadConfigFile(establishment_id: string, apikey: string) {
    return this.api.get(`clientconfiguration?establishment_id=${establishment_id}&apikey=${apikey}'`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }
}