export class Sources {
    public id: string;
    public hertaSource: string;
    public description: string;
    public type: string;
    public label: string;
    public location: {
      "cx": 0,
      "cy": 0,
      "r": 0,
      "orientation": "up"
    }
    
    constructor(_id: string, _hertaSource: string, _description: string, _type: string, _label: string) {
        this.id = _id;
        this.hertaSource = _hertaSource;
        this.description = _description;
        this.type = _type;
        this.label = _label;
    }
}