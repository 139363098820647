<div class="container-dialog">
    <div class="title">
        <h2 class="icon-secondary">{{title | translate}}</h2>
    </div>
    <form class="container-form" [formGroup]="accountForm" (ngSubmit)="apply()">
        <mat-form-field appearance="outline">
            <mat-label>{{"userSettingsPage.name" | translate}}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{"firstName" | translate}}</mat-label>
            <input matInput formControlName="firstName">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{"lastName" | translate}}</mat-label>
            <input matInput formControlName="lastName">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{"email" | translate}}</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="errorMsg">
            <div>
                Invalid email
            </div>
        </div>
        <div class="button-row">
            <button [disabled]="!isAllValid()" mat-raised-button color="primary"  type="submit">{{"Apply" | translate}}</button>
            <button mat-raised-button class="icon-secondary" (click)="closeDialog()" type="button">{{"Cancel" |
                translate}}</button>
        </div>
    </form>
</div>