import { HttpService } from "../../../@core/services/http.service";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
  })
export class NavigationService {
    constructor(private api: HttpService) {}

    changePassword(oldPwd: string, newPwd: string): Observable<any> {
        const query = `users/me/pwd`;
        const data = { "old_password": oldPwd, "password": newPwd};

        return this.api.patch(query, data).pipe(
            map((result) => {
              return { ...result };
            })
          );
    }
}