import { HttpService } from '../../../@core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SourceService {

  constructor(
    private api: HttpService
  ) { }

  getEstablishments(accountId: string): Observable<any> {

    return this.api.get(`establishments?page=1&limit=100&account_id=${accountId}`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }
  
    deleteSource(sourceId: string, establishmentId: string) {
      return this.api.delete(`establishments/${establishmentId}/sources/${sourceId}`).pipe(
        map((result) => {
          return { ...result };
        })
      );
    }
}