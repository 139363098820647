import { HttpService } from '../../../@core/services/http.service';
import { statusData } from '../models/statusData';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { groupData } from '../models/groupData';


@Injectable({
    providedIn: 'root',
  })
export class dashboardService {

    constructor(
      private api: HttpService
    ) {}

}