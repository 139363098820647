<mat-toolbar class="nav-header">

  <img src = "../../../../../assets/images/HERTAVERSE LOGO.svg" alt="Hertaverse logo" class="logo-header"/>
  

  <div class="align-center">
    <div class="select">
      <mat-form-field class="languageSelect" appearance="outline">
        <mat-select #langSelect (selectionChange)="changeLang(langSelect.value)" [(value)]="selectedLanguage">
          <div *ngFor="let lang of translate.getLangs()">
            <mat-option [value]="lang" [selected]="selectedLanguage">{{ lang }}</mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="text-account">{{ authService.user()?.username }}</span>
    <button
      mat-icon-button
      *ngIf="authService.user()?.username"
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu">
      <mat-icon class="icon-blue">account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        (click)="logOut()">
        <mat-icon class="icon-blue">logout</mat-icon>
        <span>{{"logout" | translate}}</span>
      </button>
      <button
        mat-menu-item
        [routerLink]='changePassword()'>
        <mat-icon class="icon-blue">build</mat-icon>
        <span>{{"change password" | translate}}</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-drawer-container
  class="side-nav-elements"
  autosize>
  <mat-drawer
    class="side-nav-background"
    mode="side"
    opened>
    <mat-list *ngFor="let menuItem of navItems">
      <div class="nav-card">
        <div class="nav-icon">
          <button
            class="icon-blue"
            mat-icon-button
            [routerLink]="menuItem.RoutingPath">
            <mat-icon
              matBadge="{{ menuItem.BadgeValue ?? '-' }}"
              matBadgeHidden="{{ menuItem.HideBadge === undefined ? true : menuItem.HideBadge() }}"
              *ngIf="menuItem.Icon"
              >{{ menuItem.Icon }}</mat-icon
            >
            <mat-icon
              matBadge="{{ menuItem.BadgeValue ?? '-' }}"
              matBadgeHidden="{{ menuItem.HideBadge === undefined ? true : menuItem.HideBadge() }}"
              *ngIf="menuItem.svgIcon"
              svgIcon="{{ menuItem.svgIcon }}"></mat-icon>
          </button>
        </div>
        <div class="nav-text">{{ menuItem.Name | translate }}</div>
      </div>
    </mat-list>
  </mat-drawer>
  <main><router-outlet></router-outlet></main>
</mat-drawer-container>
<mat-toolbar class="nav-footer">
  <span class="space-elements-header"></span>
  <span
    >Developed by Herta Security S.L.© 2023 | Hi-Tech Facial Recognition |
    <a
      class="text-link-footer"
      target="_blank"
      href="https://hertasecurity.com/"
      >www.herta.ai</a
    >
  </span>
</mat-toolbar>
