<div class="container-dialog">
    <div class="title">
        <h2 class="icon-secondary">{{title | translate}} {{"source" | translate}}</h2>
    </div>
    <form class="container-form" [formGroup]="userForm" (ngSubmit)="apply()">
        <mat-form-field appearance="outline" >
            <mat-label>{{"description" | translate}}</mat-label>
            <input matInput formControlName="description">
        </mat-form-field>
        <mat-form-field appearance="outline" >
            <mat-label>{{"source" | translate}}</mat-label>
            <input matInput formControlName="source">
        </mat-form-field>
        <mat-form-field appearance="outline" >
            <mat-label>{{"label" | translate}}</mat-label>
            <input matInput formControlName="label">
        </mat-form-field>
        <div class="row">
            <mat-form-field appearance="outline" >
                <mat-label>{{"cx" | translate}}</mat-label>
                <input matInput formControlName="cx">
            </mat-form-field>
            <mat-form-field appearance="outline" class="marginLR">
                <mat-label>{{"cy" | translate}}</mat-label>
                <input matInput formControlName="cy">
            </mat-form-field>
            <mat-form-field appearance="outline" >
                <mat-label>{{"r" | translate}}</mat-label>
                <input matInput formControlName="r">
            </mat-form-field>
        </div>
        <div *ngIf="(cx.invalid && (cx.dirty || cx.touched)) || 
                    (cy.invalid && (cy.dirty || cy.touched)) || 
                    (r.invalid && (r.dirty || r.touched))" class="errormsg">
            <div>
                Invalid: must be a number
            </div>
        </div>
        <mat-form-field appearance="outline" >
            <mat-label>{{"type" | translate}}</mat-label>
            <mat-select  formControlName="type">
                <mat-option value="IN">IN</mat-option>
                <mat-option value="OUT">OUT</mat-option>
                <mat-option value="INTERNAL">INTERNAL</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" >
            <mat-label>{{"orientation" | translate}}</mat-label>
            <mat-select  formControlName="orientation">
                <mat-option value="up">up</mat-option>
                <mat-option value="down">down</mat-option>
                <mat-option value="left">left</mat-option>
                <mat-option value="right">right</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="button-row">
            <button [disabled]='!isAllValid()' mat-raised-button color="primary"  type="submit">{{"Apply" | translate}}</button>
            <button mat-raised-button class="icon-secondary" (click)="closeDialog()" type="button">{{"Cancel" | translate}}</button>
        </div>
    </form>
</div>