import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { EstablishmentSettings as Establishments } from '../../models/userSettings';
import { EstablishmentService } from '../../services/establishment-settings.service';
import { DialogEstablishmentComponent } from '../dialog-establishments/dialog-establishments.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmDialog/confirmation-dialog';

@Component({
  selector: 'hrt-admin-user-settings',
  templateUrl: './establishments-settings.component.html',
  styleUrl: './establishments-settings.component.scss'
})
export class EstablishmentsSettingsComponent {
  displayedColumns: string[] = ['name', 'id', 'capacity', 'gmt', 'heatMapThresholds', 'sources', 'apiKey', 'delete'];
  pageSize = [10, 25, 50, 100];
  lenPaginator = 0;
  dataSource: MatTableDataSource<Establishments>;
  element_data: Establishments[];
  accountId: string = ''

  constructor(private EstablishmentService: EstablishmentService, public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {
    this.element_data = [];

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.accountId = params.account_id;
      this.getEstablishments();
    });
  }

  getEstablishments() {
    this.EstablishmentService
      .getEstablishments(this.accountId)
      .subscribe({
        next: (data: any[]) => {
          data = Object.keys(data).map(function (key) { return data[key]; });
          this.parseEstablishments(data);
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  downloadConfig(apiKeyId: string, id: string, name: string) {
    this.EstablishmentService
      .downloadConfigFile(id, apiKeyId)
      .subscribe({
        next: (response: any) => {
          console.log(response);
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(JSON.stringify(response));
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', `${name}.json`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  parseEstablishments(data: any[]) {
    const tmp: Establishments[] = [];

    for (const elem of data) {
      tmp.push(new Establishments(elem.name, elem.id, elem.accountId, elem.sources, elem.capacity, elem.gmt, elem.heatMapThresholds));
    }
    this.element_data = tmp;
    this.refreshTable();
  }

  refreshTable() {
    this.dataSource = new MatTableDataSource<Establishments>(this.element_data);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogEstablishmentComponent, {
      data: { accountId: this.accountId },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getEstablishments();
    });
  }

  goBack() {
    const url = `/modules/AccountSettings`;
    this.router.navigateByUrl(url);
  }

  goToSources(id: string) {
    const url = `/modules/AccountSettings/sources?establishment_id=${id}&account_id=${this.accountId}`;
    this.router.navigateByUrl(url);
  }

  regenerateKey(id: string, name) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to regenereate the API Key ?"

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        this.EstablishmentService.regenerateApiKey(this.accountId, id)
          .subscribe({
            next: (response: any) => {
              console.log(response);
              this.downloadConfig(response.api_key_id, response.id, name);
              this.getEstablishments();
              this.refreshTable();
            },
            error: () => {
              console.error("something went wrong.");
            },
          });
      }
    });
  }

  delete(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        this.EstablishmentService.deleteEstablishment(this.accountId, id)
          .subscribe({
            next: () => {
              this.getEstablishments();
              this.refreshTable();
            },
            error: () => {
              console.error("something went wrong.");
            },
          });
      }
    });
  }
}
