import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { EstablishmentsSettingsComponent } from './pages/establishments-settings/establishments-settings.component';
import { SourcesSettingsComponent } from './pages/sources-settings/sources-settings.component';

const routes: Routes = [
  {
    path: '',
    component: AccountSettingsComponent,
  },
  {
    path: 'establishments',
    component: EstablishmentsSettingsComponent,
  },
  {
    path: 'sources',
    component: SourcesSettingsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountSettingsRoutingModule { }
