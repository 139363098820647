import { Component } from '@angular/core';
import { dashboardService } from '../../services/dashboard.service';
import { statusData } from '../../models/statusData';
import { groupData } from '../../models/groupData';
import { trending } from '../../../../@shared/models/trend-enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})

export class DashboardComponent {
  status: statusData = new statusData;
  groupStatus: groupData[];
  refreshRate = 60;
  unfinishedCameras = 0;

  constructor(private dashboardService: dashboardService) {
  }

  getIcon() {
    return trending.getIcon(this.status.trend);
  }

  getColor() {
    return trending.getColor(this.status.trend);
  }

  warningColor() {
    if (this.unfinishedCameras > 0) {
      return 'error-color';
    }
  }
}

