import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AccountSettingsRoutingModule } from './settings-routing.module';

import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { DialogAccountComponent } from './pages/dialog-account/dialog-account.component';
import { EstablishmentsSettingsComponent } from './pages/establishments-settings/establishments-settings.component';
import { TimePickerComponent } from '../../@shared/modules/time-picker/time-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../@shared/components/search-input/search-input.component';
import { DialogEstablishmentComponent } from './pages/dialog-establishments/dialog-establishments.component';
import { ConfirmationDialogComponent } from './pages/confirmDialog/confirmation-dialog';
import { SourcesSettingsComponent } from './pages/sources-settings/sources-settings.component';
import { DialogSourceComponent } from './pages/dialog-source/dialog-source.component';

@NgModule({
  declarations: [AccountSettingsComponent, DialogAccountComponent, EstablishmentsSettingsComponent,
    DialogEstablishmentComponent, ConfirmationDialogComponent, SourcesSettingsComponent, DialogSourceComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, AccountSettingsRoutingModule, TranslateModule, SharedModule,
    MatInputModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatFormFieldModule, MatButtonModule,
    MatSlideToggleModule, MatMenuModule, TimePickerComponent, MatDatepickerModule, MatInputModule, FormsModule,
    SearchInputComponent
  ]
})
export class AccountSettingsModule { }
