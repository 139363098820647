import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ApiSettingsService } from '../../services/api-settings.service';
import { ApiKeySettings } from '../../models/apiKeySettings';
import { DialogApiComponent } from '../dialog-api/dialog-api.component';
import { ApiDialogSettings } from '../models/apiDialogSettings';

@Component({
  selector: 'app-api-settings',
  templateUrl: './api-settings.component.html',
  styleUrl: './api-settings.component.scss'
})
export class ApiSettingsComponent {
  displayedColumns: string[] = ['key', 'name', 'options'];
  pageSize = [10, 25, 50, 100];
  dataSource: MatTableDataSource<ApiKeySettings>;
  element_data: ApiKeySettings[];
  private accountId: string;

  constructor(private apiSettingsService: ApiSettingsService, public dialog: MatDialog) {
    this.element_data = [];
    this.getApiKeys();
  }

  refreshTable() {
    this.dataSource = new MatTableDataSource<ApiKeySettings>(this.element_data);
  }

  getApiKeys() {
    this.element_data = [];
    this.apiSettingsService
      .getApiKeys()
      .subscribe({
        next: (data: any) => {
          data = Object.keys(data).map(function (key) { return data[key]; });
          for(const elem of data) {
            this.element_data.push(new ApiKeySettings(elem.id, elem.name));
          }
          this.refreshTable();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  openDialog(sw: number, secret: string = '', apiKeySettings = null): void {

    const settings: string = ApiDialogSettings[sw];

    const dialogRef = this.dialog.open(DialogApiComponent, {data: {settings: settings, secret: secret, apiKey: apiKeySettings}, disableClose: true});

    dialogRef.afterClosed().subscribe(result => {
      this.getApiKeys();
      if (result.isCreating) {
        this.openDialog(2, result.secret);
      }
    });
  }
}
