export class AccountsAdmin {
    public id: string = "";
    public name: string = "";
    public createdAt: string = "";
    public updatedAt: string = "";

    constructor(_id?: string, _name?: string, _createdAt?: string, _updatedAt?: string) {
        this.id = _id;
        this.name = _name;
        this.createdAt = _createdAt;
        this.updatedAt = _updatedAt;
    }
}