import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { UserSettingsService } from '../../services/user-settings.service';
import { userDialogControl } from '../../models/userDialogControl';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'hrt-dialog-establishments',
  templateUrl: './dialog-establishments.component.html',
  styleUrl: './dialog-establishments.component.scss'
})
export class DialogEstablishmentComponent {

  apicontrol: userDialogControl = userDialogControl.Create;
  title: string = "register";
  accountId: string = '';
  isCreationDone: boolean = false;
  apiKeyId = "";
  establishmentId = "";


  constructor(private dialogRef: MatDialogRef<DialogEstablishmentComponent>,
    private formBuilder: FormBuilder,
    private userSettingsService: UserSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
  ) { }


  ngOnInit() {
    if (this.data.accountId) {
      this.accountId = this.data.accountId;
    }
  }

  userForm = this.formBuilder.group({
    name: ['', Validators.required],
    capacity: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    gmt: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    min: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    max: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
  });

  get capacity() {
    return this.userForm.get('capacity');
  }

  get gmt() {
    return this.userForm.get('gmt');
  }

  get min() {
    return this.userForm.get('min');
  }

  get max() {
    return this.userForm.get('max');
  }

  createNewEstablishment() {
    this.userSettingsService
      .createNewEstablishment(this.createBodyEstablishment(), this.accountId)
      .subscribe({
        next: (content: any) => {
          console.log(content);
          this.apiKeyId = content.api_key_id;
          this.establishmentId = content.id;
          this.downloadConfig();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  downloadConfig() {
    this.userSettingsService
      .downloadConfigFile(this.establishmentId, this.apiKeyId)
      .subscribe({
        next: (response: any) => {
          console.log(response);
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(JSON.stringify(response));
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          downloadLink.setAttribute('download', `${this.userForm.value.name}.json`);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  createBodyEstablishment() {
    return (
      {
        "name": this.userForm.value.name,
        "capacity": this.userForm.value.capacity,
        "gmt": this.userForm.value.gmt,
        "heatMapThresholds": {
          "min": this.userForm.value.min,
          "max": this.userForm.value.max
        }
      }
    );
  }

  apply() {
    if (this.userForm.status == 'VALID') {
      this.isCreationDone = true;
      this.createNewEstablishment();
    }
    //this.dialogRef.close(this.userForm);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isAllValid() {
    return this.userForm.valid;
  }

}
