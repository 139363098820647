import { Component, Output, EventEmitter, Input } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'time-picker',
  standalone: true,
  imports: [MatCardModule, MatSelectModule, CommonModule],
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss'
})
export class TimePickerComponent {
  @Input() hourInput: number;
  @Input() minInput: number;

  @Output() hourOutput = new EventEmitter<string>();
  @Output() minOutput = new EventEmitter<string>();

  hourselected='0';
  minuteselected='0';
  
  ngOnInit() {
    this.hourselected = this.hourInput.toString();
    this.minuteselected = this.minInput.toString();
  }

  ngOnChanges() {
    this.hourselected = this.hourInput.toString();
    this.minuteselected = this.minInput.toString();
  }
  
  hours: Array<number>;
  minutes: Array<number>;
  constructor() {
    this.hours = Array(24).fill(0).map((x,i)=>i);
    this.minutes = Array(60).fill(0).map((x,i)=>i);
  }

  changeHour() {
    this.hourOutput.emit(this.hourselected);
  }

  changeMinute() {
    this.minOutput.emit(this.minuteselected);
  }
  
}
