export class UsersAdmin {
    public id: string = "";
    public email: string = "";
    public createdAt: string = "";
    public updatedAt: string = "";
    public  disabled: boolean = false;
    public scopes : string[] = [];

    constructor(_id?: string, _email?: string, _createdAt?: string, _updatedAt?: string, _disabled?: boolean, scopes?: string[]) {
        this.id = _id;
        this.email = _email;
        this.createdAt = _createdAt;
        this.updatedAt = _updatedAt;
        this.disabled = _disabled;
        this.scopes = scopes;
    }
}