import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AccountSettingsService } from '../../services/account-settings.service';
import { Accounts } from '../../models/accounts';
import { DialogAccountComponent } from '../dialog-account/dialog-account.component';
import { PlanAccount } from '../../models/planAccount';
import { Router } from '@angular/router';
import { Subscription } from "rxjs";
import { ConfirmationDialogComponent } from '../confirmDialog/confirmation-dialog';

@Component({
  selector: 'hrt-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss'
})
export class AccountSettingsComponent {
  displayedColumns: string[] = ['name', 'id', 'establishments', 'delete'];
  pageSize = [10, 25, 50, 100];
  dataSource: MatTableDataSource<Accounts>;
  element_data: Accounts[];
  private searchRequestSub: Subscription[] = [];

  constructor(private AccountSettingsService: AccountSettingsService, public dialog: MatDialog, private router: Router) {
    this.element_data = [];
    this.getAccounts();
  }

  refreshTable() {
    this.dataSource = new MatTableDataSource<any>(this.element_data);
  }

  getAccounts(search: string = null) {
    this.element_data = [];
    const searchSub = this.AccountSettingsService
      .getAccounts(search)
      .subscribe({
        next: (data: any) => {
          data = Object.keys(data).map(function (key) { return data[key]; });
          for (const elem of data) {
            this.element_data.push(new Accounts(elem.id, elem.name, elem.establishments));
          }
          this.refreshTable();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });

    this.searchRequestSub.push(searchSub);

  }

  openDialog(element: Accounts = null, type: string = null): void {

    const dialogRef = this.dialog.open(DialogAccountComponent, { data: { element, type } });

    dialogRef.afterClosed().subscribe(() => {
      this.getAccounts();
    });
  }

  
  goBack() {
    const url = `/modules/`;
    this.router.navigateByUrl(url);
  }

  isChecked(status: string) {
    if (status == "active")
      return true;
    return false;
  }

  moveToEstablishmentPage(id: string) {
    const url = `/modules/AccountSettings/establishments?account_id=${id}`;
    this.router.navigateByUrl(url);
  }

  onSearch(search: string) {
    this.cancelPendingRequests();
    this.getAccounts(search);
  }

  cancelPendingRequests() {
    this.searchRequestSub.forEach(sub => sub.unsubscribe());
  }

  delete(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        // do confirmation actions
        this.AccountSettingsService.deleteAccount(id)
        .subscribe({
          next: () => {
            this.getAccounts();
            this.refreshTable();
          },
          error: () => {
            console.error("something went wrong.");
          },
        });
      }
    });
  }
}
