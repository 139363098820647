import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanMatchRoleGuard } from '../@core/auth/guards/canMatchRole.guard';
import { AppRoutes } from '../@core/auth/models/routes.enum';
import { ModulesComponent } from './modules.component';
import { canActivateAuthGuard } from '../@core/auth/guards/canActivateAuth.guard';

const routes: Routes = [
  {
    path: '',
    component: ModulesComponent,
    canActivate: [canActivateAuthGuard],
    children: [
      {
        path: AppRoutes.Dashboard,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.ApiSettings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./api-settings/api-settings.module').then((m) => m.ApiSettingsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.AccountSettings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./settings/settings.module').then((m) => m.AccountSettingsModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.ChangePwd,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./changePassword/ChangePassword.module').then((m) => m.ChangePasswordModule),
        canMatch: [CanMatchRoleGuard],
      },
      {
        path: AppRoutes.AccountAdminSettings,
        canActivate: [canActivateAuthGuard],
        loadChildren: () => import('./settings-admin/settings-admin.module').then((m) => m.SettingsAdminModule),
        canMatch: [CanMatchRoleGuard],
      },

      { path: '', redirectTo: AppRoutes.Dashboard, pathMatch: 'full' },
      { path: '**', redirectTo: AppRoutes.Dashboard },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModulesRoutingModule {}
