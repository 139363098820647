<div class="container">
    <div class="top-container">
            <button mat-fab color="primary" class="button" (click)="goBack()">
                <mat-label>
                    <mat-icon>arrow_back</mat-icon>
                </mat-label>
            </button>
            <button mat-fab color="primary" class="button" (click)="openDialog()">
                <mat-label>
                    <mat-icon class="button-icon">add</mat-icon>
                </mat-label>
            </button>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{'userSettingsPage.name' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.name}} </td>
            </ng-container>
          
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>{{'Id' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.id}}</td>
            </ng-container>
          
            <ng-container matColumnDef="capacity">
              <th mat-header-cell *matHeaderCellDef>{{'Capacity' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.capacity}}</td>
            </ng-container>
          
            <ng-container matColumnDef="gmt">
              <th mat-header-cell *matHeaderCellDef>{{'gmt' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.gmt}}</td>
            </ng-container>
          
            <ng-container matColumnDef="heatMapThresholds">
              <th mat-header-cell *matHeaderCellDef>{{'heatMapThresholds' | translate}}</th>
              <td mat-cell *matCellDef="let element">{{element.heatMapThresholds.min}} / {{element.heatMapThresholds.max}}</td>
            </ng-container>
          
            <ng-container matColumnDef="sources">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary" (click)="goToSources(element.id)">
                  <div class="white">{{"sources" | translate}}</div>
                </button>
              </td>
            </ng-container>
          
            <ng-container matColumnDef="apiKey">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary" (click)="regenerateKey(element.id, element.name)">
                  <div class="white">{{"regenerate api key" | translate}}</div>
                </button>
              </td>
            </ng-container>
          
            <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="primary" (click)="delete(element.id)">
                  <div class="white">{{"delete" | translate}}</div>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator
                [length]="dataSource ? dataSource.data.length : 0"
                [pageSize]="10"
                [pageSizeOptions]="pageSize"
                aria-label="Select page of cameras"
                class="mat-elevation-z8">
            </mat-paginator>
    </div>
</div>
