import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiSettingsService } from '../../services/api-settings.service';
import { ApiKeySettings } from '../../models/apiKeySettings';


@Component({
  selector: 'hrt-dialog-api',
  templateUrl: './dialog-api.component.html',
  styleUrl: './dialog-api.component.scss'
})
export class DialogApiComponent {

  title: string = "register";
  settings: string;
  secret: string = null;
  apiKey: ApiKeySettings = null;
  apiKeyName : any;

  constructor(private dialogRef: MatDialogRef<DialogApiComponent>,
    private formBuilder: FormBuilder,
    private apiSettingsService: ApiSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.settings = this.data.settings;
    this.secret = this.data.secret;
    this.apiKey = this.data.apiKey;
    this.apiKeyName  = {value: this.apiKey.name};
  }

  userForm = this.formBuilder.group({
    name: ['', Validators.required],
  });

  createNewUser() {
    this.apiSettingsService
      .createApiKeys(this.userForm.value.name)
      .subscribe({
        next: (data: any) => {
          this.dialogRef.close({ isCreating: true, secret: data.secret });
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }


  apply() {
    if (this.userForm.status == 'VALID') {
      this.createNewUser();
    }
  }


  closeDialog() {
    this.dialogRef.close({ isCreating: false, secret: '' });
  }

  deleteApiKey() {
    this.apiSettingsService
      .deleteApiKeys(this.apiKey.id)
      .subscribe({
        next: () => {
          this.closeDialog();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

}
