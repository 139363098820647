import { Component, Input } from '@angular/core';
import { AuthService } from '../../../../@core/auth/services/auth.service';
import { AppRoutes } from '../../../../@core/auth/models/routes.enum';
import { RoutesByRole } from '../../../../@core/auth/models/routes-by-role';
import { Router } from '@angular/router';
import { NavigationItem } from '../../models/navigation.item';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

const conditions = [
  'NodeCannotBeReached'.toLowerCase(),
  'AlgorithmMismatch'.toLowerCase(),
  'ErrorLicence'.toLowerCase(),
  'ErrorProductInstance'.toLowerCase(),
  'LostConnection'.toLowerCase(),
  'SynchronizationError'.toLowerCase(),
  'WrongBioComVersion'.toLowerCase(),
];

@Component({
  selector: 'ngx-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() version?: string = '';
  navItems: NavigationItem[] = [];
  userSettings = AppRoutes.UserSettings;
  apiSettings = AppRoutes.ApiSettings;
  selectedLanguage: string = '';

  constructor(
    protected authService: AuthService,
    private router: Router,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {
    this.navItems = this.getItems();

    translate.addLangs(['es', 'en', 'cat', 'fr']);
    if (localStorage.getItem('locale')) {
      translate.setDefaultLang(localStorage.getItem('locale'));
      translate.use(localStorage.getItem('locale'));
    } else {
      translate.setDefaultLang('es');
      translate.use('es');
      localStorage.setItem('locale', 'es');
    }

    this.selectedLanguage = translate.currentLang;
    this.filterMenuByUser();
  }

  changeLang(language: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    localStorage.setItem('locale', language);
    this.selectedLanguage = this.translate.currentLang;
  }


  filterMenuByUser() {
    const routesByUSer = RoutesByRole.find((x) => x.role === this.authService.user().role);
    if (routesByUSer === undefined) return;

    this.navItems = this.getItems().filter((ni) =>
      routesByUSer.routes.includes(ni.RoutingPath.split('/').pop() as AppRoutes)
    );
  }

  logOut() {
    this.authService.logout().subscribe();
  }

  changePassword() {
    return AppRoutes.ChangePwd;
  }

  getItems() {
    return [
      {
        Icon: 'dashboard',
        Name: 'navigation.dashboard',
        RoutingPath: AppRoutes.Dashboard,
      },
      {
        Icon: 'settings',
        Name: 'accounts',
        RoutingPath: AppRoutes.AccountSettings,
      },
      {
        Icon: 'settings',
        Name: 'accounts admin',
        RoutingPath: AppRoutes.AccountAdminSettings,
      },
    ];
  }
}
