import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TimePickerComponent } from '../../@shared/modules/time-picker/time-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from '../../@shared/components/search-input/search-input.component';
import { SettingsAdminRoutingModule } from './settings-admin-routing.module';
import { AccountAdminSettingsComponent } from './pages/account-admin-settings/account-admin-settings.component';
import { DialogAccountAdminComponent } from './pages/dialog-account-admin/dialog-account-admin.component';
import { UsersAdminSettingsComponent } from './pages/users-admin-settings/users-admin-settings.component';
import { DialogUsersAdminComponent } from './pages/dialog-users-admin copy/dialog-users-admin.component';

@NgModule({
  declarations: [AccountAdminSettingsComponent, DialogAccountAdminComponent, UsersAdminSettingsComponent, DialogUsersAdminComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, SettingsAdminRoutingModule, TranslateModule, SharedModule,
    MatInputModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatFormFieldModule, MatButtonModule,
    MatSlideToggleModule, MatMenuModule, TimePickerComponent, MatDatepickerModule, MatInputModule, FormsModule,
    SearchInputComponent
  ]
})
export class SettingsAdminModule { }
