import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { EstablishmentSettings as Establishments } from '../../models/userSettings';
import { DialogEstablishmentComponent } from '../dialog-establishments/dialog-establishments.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from '../confirmDialog/confirmation-dialog';
import { Sources } from '../../models/source';
import { SourceService } from '../../services/sources-settings.service';
import { DialogSourceComponent } from '../dialog-source/dialog-source.component';

@Component({
  selector: 'hrt-admin-sources-settings',
  templateUrl: './sources-settings.component.html',
  styleUrl: './sources-settings.component.scss'
})
export class SourcesSettingsComponent {
  displayedColumns: string[] = ['id', 'description', 'source', 'type', 'label', 'delete'];
  pageSize = [10, 25, 50, 100];
  lenPaginator = 0;
  dataSource: MatTableDataSource<Sources>;
  element_data: Sources[];
  establishmentId: string = '';
  accountId: string = '';
  sources: any = null;

  constructor(private SourceService: SourceService, public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {
    this.element_data = [];

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.establishmentId = params.establishment_id;
      this.accountId = params.account_id;

      this.getEstablishments();
    });
  }

  getEstablishments() {
    this.SourceService
      .getEstablishments(this.accountId)
      .subscribe({
        next: (data: any[]) => {
          data = Object.keys(data).map(function (key) { return data[key]; });
          this.findSourcesInEstablishment(data);
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  findSourcesInEstablishment(data: any[]) {
    let tmp: Establishments;

    for (const elem of data) {
      tmp = new Establishments(elem.name, elem.id, elem.accountId, elem.sources, elem.capacity, elem.gmt, elem.heatMapThresholds);
      if (tmp.id == this.establishmentId) {
        this.parseSources(tmp.sources);
      }
    }
    this.refreshTable();
  }

  parseSources(sources: any[]) {
    const tmp: Sources[] = [];
    for (const source of sources) {
      tmp.push(new Sources(source._id, source.hertaSource, source.description, source.type, source.label));
    }
    this.sources = tmp;
    this.element_data = this.sources;
  }

  refreshTable() {
    this.dataSource = new MatTableDataSource<Sources>(this.element_data);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogSourceComponent, {
      data: { establishmentId: this.establishmentId },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getEstablishments();
    });
  }

  goBack() {
    const url = `/modules/AccountSettings/establishments?account_id=${this.accountId}`;
    this.router.navigateByUrl(url);
  }

  goToSources(sources: any) {
  }

  delete(id: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        this.SourceService.deleteSource(id, this.establishmentId)
          .subscribe({
            next: () => {
              this.getEstablishments();
              this.refreshTable();
            },
            error: () => {
              console.error("something went wrong.");
            },
          });
      }
    });
  }
}
