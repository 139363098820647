<div class="container">
  <div class="top-container">
    <button mat-mini-fab color="primary" class="button" (click)="openDialog(0)">
      <mat-label>
        <mat-icon class="button-icon">add</mat-icon>
      </mat-label>
    </button>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>{{'userSettingsPage.name' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{'key' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>{{'userSettingsPage.opt' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button color="warn"(click)="openDialog(1, '', element)">{{"delete" | translate}}</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="dataSource ? dataSource.data.length : 0" [pageSize]="10" [pageSizeOptions]="pageSize"
      aria-label="Select page of cameras" class="mat-elevation-z8">
    </mat-paginator>
  </div>
</div>