import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountAdminSettingsService } from '../../services/account-admin-settings.service';
import { AccountsAdmin } from '../../models/accounts_admin';

@Component({
  selector: 'hrt-dialog-users-admin',
  templateUrl: './dialog-users-admin.component.html',
  styleUrl: './dialog-users-admin.component.scss'
})
export class DialogUsersAdminComponent {

  public title: "create";
  public account: AccountsAdmin = null;
  public time = {hours: 0, minutes: 0};
  public accountId: string = "";

  accountForm = this.formBuilder.group({
    name: ['', Validators.required],
    email:  ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    pwd: ['', Validators.required],
  });

  constructor(private dialogRef: MatDialogRef<DialogUsersAdminComponent>,
    private formBuilder: FormBuilder,
    private AccountAdminSettingsService: AccountAdminSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.accountId = this.data.accountId;
  }

  createUserAdmin() {
    this.AccountAdminSettingsService
      .createUserAdmin(this.createBodyUser())
      .subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });
  }

  createBodyUser() {
    return {
      "email": this.accountForm.value.email,
      "password": this.accountForm.value.pwd,
      "full_name": this.accountForm.value.name,
      "account_id": this.accountId,
      "scopes": [
        "account_manager"
      ],
      "disabled": false
    };
  }

  get email() {
    return this.accountForm.get('email');
  }

  apply() {
    if (this.accountForm.status == 'VALID') {
      this.createUserAdmin();
    }
  }

  isEightChar() {
    const pwd = this.accountForm.value.pwd;
    const upperLower = /(?=.*[a-z])(?=.*[A-Z])/;
    if (pwd && pwd.length >= 8)
      return upperLower.test(pwd);
    return false;
  }

  isDigit() {
    const pwd = this.accountForm.value.pwd;
    if (!pwd)
      return false;
    const hasNumber = /\d/;
    return hasNumber.test(pwd);
  }

  isSpecial() {
    const pwd = this.accountForm.value.pwd;
    if (!pwd)
      return false;
    const isSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return isSpecial.test(pwd);
  }

  isPwdOkay() {
    return (!this.isDigit() || !this.isEightChar() || !this.isSpecial());
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
