<div class="container-dialog">
    <div class="title">
        <h2 class="icon-secondary">{{title | translate}}</h2>
    </div>
    <form class="container-form" [formGroup]="accountForm" (ngSubmit)="apply()">

        <mat-form-field appearance="outline">
            <mat-label>{{"userSettingsPage.email" | translate}}</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="errorMsg">
            <div>
                {{"invalid email" | translate}}
            </div>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>{{"userSettingsPage.name" | translate}}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{"password" | translate}}</mat-label>
            <input matInput formControlName="pwd">
        </mat-form-field>

        <div class="list">
            - {{"passwordSpecs.char" | translate}}
            <mat-icon [ngClass]="{'green': isEightChar()}" class="grey">check</mat-icon>
        </div>
        <div class="list">
            - {{"passwordSpecs.digit" | translate}}
            <mat-icon [ngClass]="{'green': isDigit()}" class="grey">check</mat-icon>
        </div>
        <div class="list">
            - {{"passwordSpecs.special" | translate}}
            <mat-icon [ngClass]="{'green': isSpecial()}" n class="grey">check</mat-icon>
        </div>

        <div class="button-row" >
            <button [disabled]="isPwdOkay()" mat-raised-button color="primary" type="submit">{{"Apply" | translate}}</button>
            <button mat-raised-button class="icon-secondary" (click)="closeDialog()" type="button">{{"Cancel" |
                translate}}</button>
        </div>
    </form>
</div>