import { HttpService } from '../../../@core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { subPlan } from '../../../@shared/models/subPlans';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {

  constructor(
    private api: HttpService
  ) { }

  getAccounts(search: string): Observable<any> {

    let query = `accounts?page=1&limit=100&include_disabled=true`;

    if (search)
      query = `accounts?page=1&limit=100&include_disabled=true&search=${search}`;

    return this.api.get(query).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  createAccount(name: string, firstName: string, lastName: string, email: string, usagePlanId: string): Observable<any> {
    const data = { "name": name, "firstName": firstName, "lastName": lastName, "email": email, "usage_plan_id": usagePlanId};
    return this.api.post(`accounts`, data).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }

  deleteAccount(id: string) {
    const data = {"id": id};
    return this.api.delete(`accounts/${id}`).pipe(
      map((result) => {
        return { ...result };
      })
    );
  }
}