export class Accounts {
    public id: string = "";
    public name: string = "";
    public establishments = [];

    constructor(_id?: string, _name?: string, _establishments?: any[]) {
        this.id = _id;
        this.name = _name;
        this.establishments = _establishments;
    }
}