import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from "rxjs";
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsAdmin } from '../../models/accounts_admin';
import { AccountAdminSettingsService } from '../../services/account-admin-settings.service';
import { UsersAdmin } from '../../models/users_admin';
import { DialogUsersAdminComponent } from '../dialog-users-admin copy/dialog-users-admin.component';

@Component({
  selector: 'hrt-users-admin-settings',
  templateUrl: './users-admin-settings.component.html',
  styleUrl: './users-admin-settings.component.scss'
})
export class UsersAdminSettingsComponent {
  displayedColumns: string[] = ['email', 'scopes', 'id'];
  pageSize = [10, 25, 50, 100];
  dataSource: MatTableDataSource<AccountsAdmin>;
  element_data: UsersAdmin[];
  private searchRequestSub: Subscription[] = [];
  private accountId = '';

  constructor(public dialog: MatDialog, public AccountAdminSettingsService: AccountAdminSettingsService,
    private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.accountId = params.account_id;

      this.getUsers();
    });
  }

  refreshTable() {
    this.dataSource = new MatTableDataSource<any>(this.element_data);
  }

  getUsers(search: string = null) {
    this.element_data = [];
    const searchSub = this.AccountAdminSettingsService
      .getUsers(this.accountId, search)
      .subscribe({
        next: (data: any) => {
          data = Object.keys(data).map(function (key) { return data[key]; });
          for (const elem of data) {
            this.element_data.push(new UsersAdmin(elem.id, elem.email, elem.createdAt, elem.updatedAt, elem.disabled, elem.scopes));
          }
          this.refreshTable();
        },
        error: () => {
          console.error("something went wrong.");
        },
      });

    this.searchRequestSub.push(searchSub);

  }

  openDialog(): void {

    const dialogRef = this.dialog.open(DialogUsersAdminComponent, { data: { "accountId": this.accountId } });

    dialogRef.afterClosed().subscribe(() => {
      this.getUsers();
    });
  }

  goBack() {
    const url = `/modules/AccountAdminSettings`;
    this.router.navigateByUrl(url);
  }

  isChecked(status: string) {
    if (status == "active")
      return true;
    return false;
  }

  moveToUsers(id: string) {
    const url = `/modules/AccountSettings/establishments?account_id=${id}`;
    this.router.navigateByUrl(url);
  }

  onSearch(search: string) {
    this.cancelPendingRequests();
    this.getUsers(search);
  }

  cancelPendingRequests() {
    this.searchRequestSub.forEach(sub => sub.unsubscribe());
  }
}
