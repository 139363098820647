<div class="container">
  <mat-card class="card-naked">
    <mat-card-content class="card-login-box">
      <form
        [formGroup]="form"
        (ngSubmit)="login()">
        <mat-form-field
          class="login-form-layout"
          appearance="outline">
          <mat-label>{{ 'user' | translate }}</mat-label>
          <input
            matInput
            name="email/user"
            formControlName="email" />
          <mat-icon
            class="icon-gray"
            matSuffix
            >account_circle</mat-icon
          >
          <mat-error class="error-message-in">
            {{ 'invalid' | translate }} {{ 'user' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field
          class="login-form-layout"
          appearance="outline">
          <mat-label>{{ 'password' | translate }}</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password" />
          <button
            type="button"
            class="icon-gray"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error class="error-message-in"
            >{{ 'invalid' | translate }} {{ 'password' | translate }}
          </mat-error>
        </mat-form-field>

        <div>
          <div
            class="center"
            *ngIf="!loginValid">
            <mat-error>{{ 'invalidLoginCredentials' | translate }}</mat-error>
          </div>
        </div>

        <button
          class="login-submit-button"
          mat-raised-button
          type="submit">
          {{ 'login' | translate }}
        </button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<div class="footer">
  <mat-expansion-panel class="main-color-box-login-espansion-panel">
    <mat-expansion-panel-header class="mat-expansion-header">
      <mat-panel-title>
        <span class="main-text-login-espansion-panel">
          Herta Security S.L.© | Hi-Tech Facial Recognition Pallars, 108 | 08018 Barcelona [Spain]
          +34 936 020 888 |
          <a
            class="icon-to-espansion"
            onClick="window.open('https://hertasecurity.com/')"
            >www.herta.ai</a
          >
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ngx-herta-face-recognition
      class="link-click-light"
      onClick="window.open('https://hertasecurity.com/')">
    </ngx-herta-face-recognition>
  </mat-expansion-panel>
</div>
