import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModulesRoutingModule } from './modules-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationModule } from './navigation/navigation.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModulesComponent } from './modules.component';
import { DashboardModule } from './dashboard/dashboard.module';

@NgModule({
  declarations: [ModulesComponent],
  exports: [NavigationModule, MiscellaneousModule, DashboardModule],
  imports: [
    CommonModule,
    ModulesRoutingModule,
    TranslateModule,
    MatFormFieldModule,
    MatCardModule,
    MatTooltipModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NavigationModule,
    MiscellaneousModule,
    MatNativeDateModule,
    ReactiveFormsModule,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModulesModule {}
