import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginValid = true;
  formError = '';
  panelOpenState = false;
  hide = true;
  form = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  login() {
    this.authService
      .login(this.form.controls.email.value ?? '', this.form.controls.password.value ?? '')
      .subscribe({
        next: () => {
          this.loginValid = true;
          this.router.navigateByUrl('/');
        },
        error: () => {
          this.loginValid = false;
        },
      });
  }
}
