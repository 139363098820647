<mat-card class="card-container">
    <mat-form-field class="half-card">
        <mat-label>Hour</mat-label>
        <mat-select [(value)]="hourselected" (selectionChange)="changeHour()">
            <div *ngFor="let hour of hours">
                <mat-option value={{hour}}>{{hour}}</mat-option>
            </div>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="half-card">
        <mat-label>minute</mat-label>
        <mat-select [(value)]="minuteselected" (selectionChange)="changeMinute()">
            <div *ngFor="let minute of minutes">
                <mat-option value={{minute}}>{{minute < 10 ? "0" + minute : minute}}</mat-option>
            </div>
        </mat-select>
    </mat-form-field>
</mat-card>